'use strict'

const $ = require('jquery')

$(() => {
  function setMaxSubaccountsVisibility (e) {
    if ($('#subaccountsAllowed').prop('checked')) {
      $('#subaccountDetails').show()
    } else {
      $('#subaccountDetails').hide()
    }
  }

  $('#subaccountsAllowed').change(setMaxSubaccountsVisibility)

  setMaxSubaccountsVisibility()

  function setCreditLineVisibility (e) {
    if ($('#allowPaymentOnAccount').prop('checked')) {
      $('#paymentOnAccountDetails').show()
    } else {
      $('#paymentOnAccountDetails').hide()
    }
  }

  $('#allowPaymentOnAccount').change(setCreditLineVisibility)

  setCreditLineVisibility()

  function addUserTypeahead (fieldSelector) {
    $(fieldSelector).typeahead({
      highlight: true
    }, {
      name: 'username',
      display: 'username',
      source: (query, syncResults, asyncResults) => {
        $.get('/userSearch?q=' + encodeURIComponent(query), asyncResults)
      }
    })
    // This fix is required to make the error messages still show because Typeahead would cut the connection between the <input> and the <span class="invalid-feedback">
    $(fieldSelector).closest('.twitter-typeahead').next('.invalid-feedback').insertAfter(fieldSelector)
  }

  addUserTypeahead('#ownerUsername')
  addUserTypeahead('#referrerUsername')

  const currentEmail = $('#email').val()

  $('#email').on('change keyup', event => {
    $('.sendVerificationMail').prop('disabled', $('#email').val() !== currentEmail)
    if (currentEmail !== $('#email').val()) {
      $('.verificationNote').text('please click on the "Save" button first, after changing your email. This will also send you a verification email')
    } else {
      $('.verificationNote').text('')
    }
  })
})
